import { Injectable } from '@angular/core';

import { Config } from '../config/config';
import { HttpLayerService } from './http-layer.service';
import { retry } from 'rxjs/operators';
import { config } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonServiceLayerService {

  constructor(
    private http: HttpLayerService
  ) { }

  public calibrationModule = {
    calibrationLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.calibrationLoader, payLoad);
      // return this.http.get(Config.JSON.calibrationLoader);
    },
    requestCalibrationLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestCalibrationLoader);
    },
    triggerSequence: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.trigSequence, payLoad);
    },
    fetchTrigger: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.fetchTrigger, payLoad);
      // return this.http.get(Config.JSON.fetchTrigger);
    },
    qualitycontrolcharts: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.qualitycontrolcharts, payLoad);
      // return this.http.get(Config.JSON.qualitycontrolcharts);
    },
    requestQualityControl: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.qualitycontrolChartsLoader);
    },
    requestCusumReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestCusumReport, payLoad);
      // return this.http.get(Config.JSON.requestCusumReport);
    },
    requestEwmaReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestEwmaReport, payLoad);
      // return this.http.get(Config.JSON.requestEwmaReport);
    },
    requestShewhartReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestShewhartReport, payLoad);
      // return this.http.get(Config.JSON.requestShewhartReport);
    },
    callibrationReprot: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.callibrationReprot, payLoad);
      // return this.http.get(Config.JSON.callibrationReprot);
    },
    remoteCalibrationStatus: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.remoteCalibrationStatus, payLoad);
      // return this.http.get(Config.JSON.remoteCalibrationStatus);
    },
    approveCalibration: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.approvecalibration, payLoad);
    },
    calendarCalibration: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.calendarCalibration, payLoad);
      // return this.http.get(Config.JSON.calendarCalibration);
    },
    addSequence: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.addSequence, payLoad);
    },
    addQual2: (payLoad) => {
      return this.http.get(Config.JSON.loadAddQual2);
    },
    loadQual2Table: (payLoad) => {
      return this.http.get(Config.JSON.q2Table);
    },
    loadQual2ASTTable: (payLoad) => {
      return this.http.get(Config.JSON.q2astTable);
    },
  };
  public diagnosticModule = {
    diagnosticsReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.diagnosticsReport, payLoad);
      // return this.http.get(Config.JSON.diagnosticsReport);
    },
    requestdiagnosticsLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestdiagnosticsLoader);
    },
    analyserStatus: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.analyserStatus, payLoad);
      // return this.http.get(Config.JSON.analyserStatus);
    },
    requestAnlyserStatus: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.analyserStatus);
    },
    diagnosticParameterReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.diagnosticParameterReport, payLoad);
      // return this.http.get(Config.JSON.diagnosticParameterReport);
    },
    requestdiagnosticsParamLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestdiagnosticsParamLoader);
    },
  };
  public others = {
    generateCheckKey: (payLoad, disableToken?) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.generateCheckKey, payLoad, undefined, undefined, undefined, disableToken);
      // return this.http.get(Config.SERVICE_IDENTIFIER.generateCheckKey, payLoad);
    },
    message: (payLoad, disableToken?) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.message, payLoad, undefined, undefined, undefined, disableToken);
      // return this.http.get(Config.JSON.message);
    },
    login: (payLoad, disableToken?) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.login, payLoad, true, undefined, undefined, disableToken);
      // return this.http.get(Config.JSON.login);
    },
    logout: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.logout, payLoad);
    },
    navHeader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.siteDashboardTabLoader, payLoad);
      // return this.http.get(Config.JSON.navHeader);
    },
    navHeaderDescription: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.siteDashboardLoader, payLoad);
      return this.http.get(Config.JSON.navHeaderDescription);
    },
    navDescriptionIcon: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.siteDashboardLoader, payLoad);
      return this.http.get(Config.JSON.navHeaderInfoIcon);
    },

    siteList: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.siteList, payLoad);
      // return this.http.get(Config.JSON.siteList);
    },
    getTableDataBasedOnSite: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.getTableDataBasedOnSite, payLoad);
      // return this.http.get(Config.JSON.getTableDataBasedOnSite);
    },
    fetchAuditAlarm: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.fetchAuditAlarm, payLoad);
      // return this.http.get(Config.JSON.fetchAuditAlarm);
    },
    todayAlarm: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.todayAlarm, payLoad);
      // return this.http.get(Config.JSON.todayAlarm);
    },
    RequestAlertsReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
    },
    alarmReportLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.alarmReportLoader, payLoad);
      // return this.http.get(Config.JSON.alarmReportLoader);
    },
    staticFilters: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.staticFilters, payLoad);
      // return this.http.get(Config.JSON.staticFilters);
    },
    formJsonLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
    },
    getStateMetaData: () => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      return this.http.get(Config.JSON.stateMetaData, false, true);
    },
    forgotPassword: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.forgotPassword, payLoad);
    },
    feedback: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.feedback, payLoad);
    },
    aqiPortalData: (payLoad) => {
      // return this.http.get(Config.JSON.aqiPortalData, false, true);
      return this.http.postText(Config.SERVICE_IDENTIFIER.publicAQIMapData, payLoad);
    },
    aqiPortalDataChart: (payLoad) => {
      // return this.http.get(Config.JSON.aqiPortalDataChart, false, true);
      return this.http.postText(Config.SERVICE_IDENTIFIER.publicAQIChartData, payLoad);
    },
    publicDropDownData: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.publicDropDownData, payLoad);
    },
    aqiInfotable: (payLoad) => {
      return this.http.get(Config.JSON.aqiInfotable, false, true);
    }
  };
  public reportsModule = {
    averageReportFormLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.averageReportFormLoader);
    },
    requestMultipleAverageReport: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.requestAverageReport, payLoad);
      return this.http.get(Config.JSON.requestMultipleAverageReport);
    },
    requestMonthlyAverage: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestMonthlyAverage, payLoad);
      // return this.http.get(Config.JSON.requestMonthlyAverage);
    },
    requestShiftReports: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestShiftReports, payLoad);
      // return this.http.get(Config.JSON.requestShiftReports);
    },
    requestFortnightReports: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestFortnightReports, payLoad);
    },
    requestAverageReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestAverageReport, payLoad);
      // return this.http.get(Config.JSON.requestAverageReport);
    },
    requestRealTimeReport: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.requestRealTimeReport, payLoad);
      return this.http.get(Config.JSON.requestRealTimeReport);
    },
    requestSiteInfo: (payLoad, hideLoader?) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.requestSiteInfo, payLoad, hideLoader);
      return this.http.get(Config.JSON.requestSiteInfo, hideLoader);
    },
    requestMatrixdata: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestMatrixdata, payLoad);
      // return this.http.get(Config.JSON.requestMatrixdata);
    },
    alarmReportLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.alarmReportLoader, payLoad);
      // return this.http.get(Config.JSON.alarmReportLoader);
    },
    requestDynamicReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestDynamicReport, payLoad);
      // return this.http.get(Config.JSON.requestDynamicReport);
    },
    requestDailyReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestDailyReport, payLoad);
      // return this.http.get(Config.JSON.requestDailyReport);
    },
    dynamicLiveData: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.dynamicLiveData, payLoad);
      return this.http.get(Config.JSON.dynamicLiveData);
    },
    requestTotalizerReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestTotalizerReport, payLoad);
      // return this.http.get(Config.JSON.requestTotalizerReport);
    },
    iedReportCreate: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.iedReportCreate, payLoad);
      return this.http.get(Config.JSON.iedReportCreate);
    },
    iedreportloader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.iedreportloader, payLoad);
      // return this.http.get(Config.JSON.iedreportloader);
    },
    hourlyExceedance: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.hourlyExceedance, payLoad);
      // return this.http.get(Config.JSON.hourlyExceedance);
    },
    dataAvailabilityReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.dataAvailabilityReport, payLoad);
      // return this.http.get(Config.JSON.dataAvailabilityReport);
    },
    siteParameter: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.siteParameter, payLoad);
    },
    exceedanceReportLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.exceedanceReportLoader, payLoad);
      // return this.http.get(Config.JSON.exceedanceReportLoader);
    },
    calculateAverageExceedance: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.calculateAverageExceedance, payLoad);
      // return this.http.get(Config.JSON.calculateAverageExceedance);
    },
    // Filters
    requestHeadersExceedanceReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestHeadersExceedanceReport);
    },
    requestHeadersMatrixReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestHeadersMatrixReport);
    },
    requestHeadersShiftReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestHeadersShiftReport);
    },
    requestHeadersDataValidationReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestHeadersShiftReport);
    },
    requestHeadersStationwiseReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestHeadersStationwiseReport, payLoad);
      // return this.http.get(Config.JSON.requestHeadersStationwiseReport);
    },
    requestHeadersDailySummaryReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestHeadersDailySummaryReport);
    },
    requestHeadersMonthlySummaryReport: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      return this.http.get(Config.JSON.requestHeadersMonthlySummaryReport);
    },
    requestHeadersRawReport: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      return this.http.get(Config.JSON.requestHeadersRawReport);
    },
    requestHeadersDynamicLiveData: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.requestHeadersDynamicLiveData, payLoad);
      return this.http.get(Config.JSON.requestAverageReport);
    },
    requestHeadersTotalizerReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestHeadersTotalizerReport);
    },
    requestHeadersDynamicLiveReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestHeadersDynamicLiveReport);
    },
    rawGraphLoader: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.rawGraphLoader, payLoad);
      return this.http.get(Config.JSON.rawGraphLoader);
    },
    dynamicLiveReportGraphLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.dynamicLiveReportGraphLoader, payLoad);
      // return this.http.get(Config.JSON.dynamicLiveReportGraphLoader);
    },
    totalizerGraphLoader: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.totalizerGraphLoader, payLoad);
      return this.http.get(Config.JSON.rawGraphLoader);
    },
    monthlySummaryGraphLoader: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.monthlySummaryGraphLoader, payLoad);
      return this.http.get(Config.JSON.monthlySummaryGraphLoader);
    },
    scheduledReportLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.scheduledReportLoader);
    },
    requestHeadersIEDComplainceReport: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.requestHeadersIEDComplainceReport, payLoad);
      return this.http.get(Config.JSON.requestHeadersIEDComplainceReport);
    },
    iedComplainceGraphLoader: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.iedComplainceGraphLoader, payLoad);
      return this.http.get(Config.JSON.iedComplainceGraphLoader);
    },
    parameterSelectionReportLoader: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.parameterSelectionReportLoader, payLoad);
      return this.http.get(Config.JSON.parameterSelectionReportLoader);
    },
    scheduledGraphLoader: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.scheduledGraphLoader, payLoad);
      return this.http.get(Config.JSON.scheduledGraphLoader);
    },
    requestHeadersExceedanceCountLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestHeadersExceedanceCountLoader);
    },
    requestHeadersMonthlyReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
    },
    requestExceedenceFilterLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestExceedenceFilterLoader);
    },
    ParameterReportLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.ParameterReportLoader);
    },
    fetchAverageData: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.fetchAverageData, payLoad);
      // return this.http.get(Config.JSON.fetchAverageData);
    },
    allMetReports: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.fetchAverageData, payLoad);
      // return this.http.get(Config.JSON.allMetReports);
    },
    metrologicalReportLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.metrologicalReportLoader);
    },
    getMetroData: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.getMetroData, payLoad);
      // return this.http.get(Config.JSON.getMetroData);
    },
    requestParameterSummary: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestParameterSummary, payLoad);
      // return this.http.get(Config.JSON.requestParameterSummary);
    },
    fetchScheduleDetails: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.fetchScheduleDetails, payLoad);
    },
    batchReportLoader: () => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.batchReportLoader);
      // return this.http.get(Config.JSON.batchReportLoader);
    },
    addBatchReportDetails: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.addBatchReportDetails, payLoad);
    },
    editBatchReportDetails: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.editBatchReportDetails, payLoad);
      // return this.http.get(Config.JSON.editBatchReportDetails);
    },
    deleteBatchReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteBatchReport, payLoad);
    },
    downloadbatchCsv: (payLoad) => {
      window.open(Config.SERVICE_IDENTIFIER.getBatchCSVReport + '?scheduleId=' + payLoad);
    },
    getBatchPDFReport: (payLoad) => {
      window.open(Config.SERVICE_IDENTIFIER.getBatchPDFReport + '?scheduleId=' + payLoad);
    },
    download: (payLoad) => {
      window.open(Config.SERVICE_IDENTIFIER.download + '?name=' + payLoad);
    },
    requestDataAvailabilityDayWiseReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestDataAvailabilityDayWiseReport, payLoad);
    },
    requestAQIParameterReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestAQIParameterReport, payLoad);
      // return this.http.get(Config.JSON.requestAQIParameterReport);
    },
    requestNoiseTrendsData: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestNoiseTrendsData, payLoad);
      // return this.http.get(Config.JSON.requestAQIParameterReport);
    },
    AQITrendsReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.AQITrendsReport, payLoad);
      // return this.http.get(Config.JSON.AQITrendsReport);
    },
    AQIFormJson: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.AQIFormJson, payLoad);
      // return this.http.get(Config.JSON.AQIFormJson);
    },
    requestStationDetails: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestStationList, payLoad);
      // return this.http.get(Config.JSON.allStationData);
    },
    getComparisonReportDetails: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.getComparisonData, payLoad);
      // return this.http.get(Config.JSON.getComparisonData);
    },
    CompareExcelService: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.getComparisonExcelData, payLoad);
      // return this.http.get(Config.JSON.getComparisonData);
    }
  };

  public ComplaintsModule = {
    complaintsManagementFilter: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.complaintsManagementFilter, payLoad);
      return this.http.get(Config.JSON.complaintsManagementFilter);
    },
    complaintManagementTableLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.complaintManagementTableLoader, payLoad);
      // return this.http.get(Config.JSON.complaintManagementTableLoader);
    },
    complaintManagementTabLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.complaintManagementTabLoader, payLoad);
      //  return this.http.get(Config.JSON.complaintManagementTabLoader);
    },
    complaintSummary: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.complaintSummary, payLoad);
      // return this.http.get(Config.JSON.viewComplaintLoader);
    },
    viewComplaintLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.viewComplaintLoader, payLoad);
      // return this.http.get(Config.JSON.viewComplaintLoader);
    },
    deleteComplaintLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteComplaintLoader, payLoad);
      // return this.http.get(Config.JSON.deleteComplaintLoader);
    },
    categoryDeleteAddAction: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.categoryDeleteAddAction, payLoad);
      // return this.http.get(Config.JSON.deleteComplaintLoader);
    },
    configuredComplaints: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteComplaintLoader, payLoad);
      // return this.http.get(Config.JSON.complaintManagementTabLoader);
    },
    complaintAssignment: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.complaintAssignment, payLoad);
      //  return this.http.get(Config.JSON.complaintManagementTabLoader);
    },
    complaintStatus: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.complaintStatus, payLoad);
      // return this.http.get(Config.JSON.complaintManagementTabLoader);
    },
    configureComplaintsTable: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.complaintConfigurationTableLoader, payLoad);
      // return this.http.get(Config.JSON.complaintManagementTableLoader);
    },
    downloadPDF: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.downloadComplaintPDF, payLoad);
    },
    complaintEntryFields: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.complaintEntryFields, payLoad);
      // return this.http.get(Config.JSON.complaintEntryFields);
    },
    complaintEntryUploadFields: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.complaintEntryUploadFields, payLoad, false, {}, false, true);
      // return this.http.get(Config.JSON.complaintEntryUploadFields);
    },
    complaintTrackerData: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.complaintTrackerData, payLoad, false, {}, false, true);
      // return this.http.get(Config.JSON.complaintTrackerData);
    },
    complaintEntryDropDownData: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.complaintEntryFields, payLoad);
      return this.http.get(Config.JSON.complaintEntryDropDownData);
    },
    submitManualComplaint: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.submitManualComplaint, payLoad, false, {}, false, true);
      // return this.http.get(Config.JSON.complaintEntryDropDownData);
    },
    complaintEnteredFields: (payLoad) => {
      return this.http.get(Config.JSON.complaintEnteredFields);
    },
    addInspectionComplaints: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.addInspectionComplaints, payLoad);
      // return this.http.get(Config.JSON.complaintEntryFields);
    },
    inspectionMultiHeaderTable: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.inspectionMultiHeaderTable, payLoad);
      // return this.http.get(Config.JSON.inspectionMultiHeaderTable);
    },
    inspectionExcelDownload: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.inspectionExcelDownload, payLoad);
      // return this.http.get(Config.JSON.inspectionMultiHeaderTable);
    },
    feedbackTable: (payLoad) => {
      // return this.http.get(Config.JSON.feedbackTable);
      return this.http.postText(Config.SERVICE_IDENTIFIER.fetchFeedback, payLoad);
    }
  };

  public maintenanceModule = {
    maintenanceLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.maintenanceLoader, payLoad);
      // return this.http.get(Config.JSON.maintenanceLoader);
    },
    requestMaintenanceLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestMaintenanceLoader);
    },
    saveMaintenance: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.saveMaintenance, payLoad);
    },
    maintenanceReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.maintenanceReport, payLoad);
      // return this.http.get(Config.JSON.maintenanceReport);
    },
    requestsystemstatusLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestMaintenanceLoader);
    },
    operationSetup: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.operationSetup, payLoad);
      return this.http.get(Config.JSON.operationSetup);
    },
    loadAddOperatingHours: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      return this.http.get(Config.JSON.loadAddOperHours);
    },
    operationhrsInsert: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.operationhrsInsert, payLoad);
    },
    updateOperatingHours: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.updateOperatingHours, payLoad);
    },
    deleteOperationSetup: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteOperationSetup, payLoad);
    }
  };
  public anayticReports = {
    requestHistogramReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestHistogramReport, payLoad);
      // return this.http.get(Config.JSON.requestHistogramReport);
    },
    histogramReportLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.histogramReportLoader);
    },
    loadGeoJson: (disableToken?) => {
      return this.http.get(Config.JSON.stateGeojson, undefined, disableToken);
    },
    loadStateGeoJson: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.stateGeojson, payLoad);
      // return this.http.get(Config.JSON.stateGeojson, undefined, disableToken);
    },
    loadGeoJsonDM: (disableToken?) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.stateGeojson, payLoad);
      return this.http.get(Config.JSON.stateGeojson, undefined, disableToken);
    },
    getWindRose: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.getWindRose, payLoad);
      // return this.http.get(Config.JSON.getWindRose);
    },
    windRoseReportLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.windroseReportLoader);
    },
    requestPercentileReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestPercentileReport, payLoad);
      // return this.http.get(Config.JSON.requestPercentileReport);
    },
    percentileReportLoader: (payLoad) => {
      // return this.http.get(Config.JSON.histogramReportLoader);
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
    },
    requestUptimeReport: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.requestUptimeReport, payLoad);
      return this.http.get(Config.JSON.requestUptimeReport);
    },
    uptimeReportLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.histogramReportLoader);
    },
    requestScatterReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestScatterReport, payLoad);
      // return this.http.get(Config.JSON.requestScatterReport);
    },
    requestAllAnalytics: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.requestAllAnalytics, payLoad);
      return this.http.get(Config.JSON.requestAllAnalytics);
    },
    scatterplotReportLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.scatterPlotReportLoader);
    },
    normalizationVerifyData: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.normalizationVerifyData, payLoad);
      // return this.http.get(Config.JSON.normalizationVerifyData);
    },
    normalizationReportLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.normalizationReportLoader);
    },
    saveAnalytics: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.saveAnalytics, payLoad);
      // return this.http.get(Config.JSON.saveAnalytics);
    },
    analyticsReportLoad: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.analyticsReportLoad, payLoad);
      // return this.http.get(Config.JSON.analyticsReportLoad);
    },
    getPolarisedWindrose: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.getPolarisedWindrose, payLoad);
      // return this.http.get(Config.JSON.getPolarisedWindrose);
    },

  };
  public realTimeDashboardModule = {
    newDashboardView: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.newDashboardView, payLoad);
      // return this.http.get(Config.JSON.newDashboardView);
    },
    sitedetails: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.sitedetails, payLoad);
      // return this.http.get(Config.JSON.sitedetails);
    },
    stationData: (payLoad, hideLoader?) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.stationData, payLoad, hideLoader);
      // return this.http.get(Config.JSON.stationData, hideLoader);
    },
    geomapLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.geochartmapLoader, payLoad);
      // return this.http.get(Config.JSON.geochartmapLoader);
    },
    geomapLoaderDM: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.geochartmapLoaderDM, payLoad);
      return this.http.get(Config.JSON.geochartmapLoaderDM);
    },
    geomapLoaderDashboardDM: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.geochartmapLoaderDM, payLoad);
      return this.http.get(Config.JSON.geomapLoaderDashboardDM);
    },
    loadGeoJson: () => {
      return this.http.get(Config.JSON.getFujairahGeoJson);
    },
    publicAqiMap: (payLoad, disableToken?) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.publicAqiMap, payLoad, undefined, undefined, undefined, disableToken);
      // return this.http.get(Config.JSON.publicAqiMap);
    },
    standard_metadata: (payLoad, disableToken?) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.standard_metadata, payLoad, undefined, undefined, undefined, disableToken);
      // return this.http.get(Config.JSON.standard_metadata);
    },
    paramChart: (payLoad, disableToken?) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.paramChart, payLoad, undefined, undefined, undefined, disableToken);
      // return this.http.get(Config.JSON.paramChart);
    },
    getSitesList: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.getSitesList, payLoad);
      return this.http.get(Config.JSON.getSitesList);
    },
    getStationInfo: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.getStationInfo, payLoad);
      // return this.http.get(Config.JSON.getStationInfo);
    },
    siteParameterStatus: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.siteParameterStatus, payLoad);
      // return this.http.get(Config.JSON.siteParameterStatus);
    },
    fetchAverageMetaData: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.fetchAverageMetaData);
    },
    fetchLiveData: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.fetchLiveData, payLoad);
      // return this.http.get(Config.JSON.fetchLiveData);
    },
    fetchDashboardDataDM: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.fetchDashboardDataDM, payLoad);
      return this.http.get(Config.JSON.fetchDashboardDataDM);
    },
    siteStatus: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.siteStatus, payLoad);
      return this.http.get(Config.JSON.siteStatus);
    },
    fetchStationParamData: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.fetchStationParamData, payLoad);
      return this.http.get(Config.JSON.fetchStationParamData);
    },
    getNoiseStationList: (payLoad, disableToken?) => {
      // return this.http.get(Config.JSON.GET_NOISE_STATION_LIST);
      // return this.http.post(Config.SERVICE_IDENTIFIER.GET_NOISE_STATION_LIST, payLoad);
      return this.http.postText(Config.SERVICE_IDENTIFIER.GET_NOISE_STATION_LIST, payLoad, undefined, undefined, undefined, disableToken);
    },
    getNoiseTableData: (payLoad) => {
      return this.http.get(Config.JSON.GET_NOISE_TABLE_DATA);
    },
    goaAirDashboard: (payLoad) => {
      return this.http.get(Config.JSON.goaAirDashboard);
    }
  };
  public auditLogsModule = {
    glensVersions: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.glensVersions, payLoad);
      // return this.http.get(Config.JSON.glensVersions);
    },
    requestauditLog: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestauditLog, payLoad);
      // return this.http.get(Config.JSON.requestauditLog);
    },
    requestAuditlogLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
    }
  };
  public commentsModule = {
    FetcheComment: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.fetcheComment, payLoad);
      // return this.http.get(Config.JSON.FetcheComment);
    },
    requestNotification: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
    },
    requestAverageReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestAverageReport, payLoad);
      // return this.http.get(Config.JSON.requestAverageReport);
    },
    PostDataValidation: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.PostDataValidation, payLoad);
      // return this.http.get(Config.JSON.PostDataValidation);
    },
    requestPostDataValidation: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.requestPostDataValidation);
    }
  };
  public setupModule = {
    saveCatchmentArea: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.saveCatchmentArea, payLoad);
      // return this.http.get(Config.JSON.saveCatchmentArea);
    },
    loadSiteDetails: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.siteSetupLoader, payLoad);
      // return this.http.get(Config.JSON.loadSiteDetails);
    },
    advancedSetupLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.advancedSetupLoader, payLoad);
      // return this.http.get(Config.JSON.advancedSetupLoader);
    },
    advancedParameterReportLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.advancedParameterReportLoader);
    },
    advancedParameterSave: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.editadvancedparameter, payLoad);
      // return this.http.get(Config.JSON.advancedParameterReportLoader);
    },
    addAdvancedParameter: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.addadvancedparameter, payLoad);
      // return this.http.get(Config.JSON.advancedParameterReportLoader);
    },
    advancedParameterDelete: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteadvancedparameter, payLoad);
      // return this.http.get(Config.JSON.advancedParameterReportLoader);
    },
    loadNormalization: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.loadNormalization, payLoad);
      // return this.http.get(Config.JSON.loadNormalization);
    },
    loadNormalizationFilter: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadNormalizationFilter, payLoad);
      return this.http.get(Config.JSON.loadNormalizationFilter);
    },
    loadFilter: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadFilter, payLoad);
      return this.http.get(Config.JSON.loadFilter);
    },
    loadSiteConfigFilter: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.loadSiteConfigFilter);
    },
    siteConfigLoad: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.siteConfigLoad, payLoad);
      // return this.http.get(Config.JSON.siteConfigLoad);
    },
    loadAddUserFilter: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadAddUserFilter, payLoad);
      return this.http.get(Config.JSON.loadAddUserFilter);
    },
    loadUser: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadUser, payLoad);
      return this.http.get(Config.JSON.loadUser);
    },
    loadUserList: (payLoad) => {
      console.log(payLoad);
      return this.http.postText(Config.SERVICE_IDENTIFIER.userSetup, payLoad);
      // return this.http.get(Config.JSON.loadUser);
    },
    addUser: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.addUser, payLoad);
    },
    editUser: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.editUser, payLoad);
    },
    editPassword: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.editPassword, payLoad);
    },
    configureDashboardType: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.configureDashboardType, payLoad);
    },
    deleteUser: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteUser, payLoad);
    },
    loadPasswordChangeModal: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadPasswordChangeModal, payLoad);
      return this.http.get(Config.JSON.loadPasswordChangeModal);
    },
    fuelSetup: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.fuelSetup, payLoad);
      // return this.http.get(Config.JSON.fuelSetup);
    },
    fuelSetupInsert: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.fuelSetupInsert, payLoad);
    },
    fuelEdit: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.fuelEdit, payLoad);
    },
    fuelDelete: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.fuelDelete, payLoad);
    },
    loadAddFuelFilter: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadAddFuelFilter, payLoad);
      return this.http.get(Config.JSON.loadAddFuelFilter);
    },
    networkDetails: (payLoad) => {
      return this.http.get(Config.SERVICE_IDENTIFIER.networkDetails, payLoad);
      // return this.http.get(Config.JSON.networkDetails);
    },
    loadAddNetwork: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadAddNetwork, payLoad);
      return this.http.get(Config.JSON.loadAddNetwork);
    },
    LoadSiteDetails: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      return this.http.get(Config.JSON.loadGeneralSettingsLoader);
    },
    fetchShift: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.fetchShift, payLoad);
      // return this.http.get(Config.JSON.fetchShift);
    },
    loadAddGeneralFilter: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadAddGeneralFilter, payLoad);
      return this.http.get(Config.JSON.loadAddGeneralFilter);
    },
    generalSetupLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.generalSetuptLoader);
    },
    // uploadLogo: (payLoad, data) => {
    //   return this.http.postFile(Config.SERVICE_IDENTIFIER.uploadLogo, payLoad, data);
    // },
    uploadLogo: (payLoad, data) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.uploadLogo, payLoad);
    },
    createShift: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.createShift, payLoad);
    },
    deleteShift: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteShift, payLoad);
    },
    loadStationDetails: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.setupStationLoader, payLoad);
      // return this.http.get(Config.JSON.loadStationDetails);
    },
    loadMonitoringStationFilter: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.loadMonitoringStationFilter);
    },
    loadAddMonitoringStation: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadAddMonitoringStation, payLoad);
      return this.http.get(Config.JSON.loadAddMonitoringStation);
    },
    collectorSetup: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
    },
    loadCollector: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.loadCollector, payLoad);
      // return this.http.get(Config.JSON.loadCollector);
    },
    loadAddCollector: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.CollectorFilter, payLoad);
      // return this.http.get(Config.JSON.loadAddCollector);
    },
    loadAddValueCollector: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadAddValueCollector, payLoad);
      return this.http.get(Config.JSON.loadAddValueCollector);
    },
    addCollector: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.addCollector, payLoad);
      // return this.http.get(Config.JSON.commonSuccessJson);
    },
    getEditColectorData: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.editloadCollector, payLoad);
      // return this.http.get(Config.JSON.commonSuccessJson);
    },
    updateCollector: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.updateCollector, payLoad);
      // return this.http.get(Config.JSON.commonSuccessJson);
    },
    deleteCollector: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteCollector, payLoad);
      // return this.http.get(Config.JSON.commonSuccessJson);
    },
    cylinderSetupLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.cylinderSetupLoader, payLoad);
      // return this.http.get(Config.JSON.cylinderSetupLoader);
    },
    addCylinder: (payLoad, data) => {
      return this.http.postFile(Config.SERVICE_IDENTIFIER.addCylinder, payLoad, data);
    },
    deleteCylinder: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteCylinder, payLoad);
    },
    loadAddNewCylinder: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadAddNewCylinder, payLoad);
      return this.http.get(Config.JSON.loadAddNewCylinder);
    },
    smsLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.smsLoader, payLoad);
      // return this.http.get(Config.JSON.smsLoader);
    },
    loadAddSms: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadAddSms, payLoad);
      return this.http.get(Config.JSON.loadAddSms);
    },
    loadFeatures: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.loadUserRole, payLoad);
      // return this.http.get(Config.JSON.loadFeatures);
    },
    loadAddUserRole: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadAddUserRole, payLoad);
      return this.http.get(Config.JSON.loadAddUserRole);
    },
    saveFeatures: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.addUserRole, payLoad);
    },
    editUserRole: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.editUserRole, payLoad);
    },
    deleteUserRole: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteUserRole, payLoad);
    },
    alertMailConfigLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.alertMailConfigLoader, payLoad);
      // return this.http.get(Config.JSON.alertMailConfigLoader);
    },
    alertMailConfigRequest: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.alertMailConfigRequest, payLoad);
    },
    displayLoad: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.displayLoad, payLoad);
      return this.http.get(Config.JSON.displayLoad);
    },
    loadDisplayBoardDetails: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.displayLoad, payLoad);
      return this.http.get(Config.JSON.displayBoardWindow);
    },
    loadDisplaySave: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadDisplaySave, payLoad);
      return this.http.get(Config.JSON.loadDisplaySave);
    },
    parameterSetupLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.parameterSetupLoader, payLoad);
      // return this.http.get(Config.JSON.parameterSetupLoader);
    },
    loadParameterFilter: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.loadParameterFilter);
    },
    editNewParameter: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.editParameter, payLoad);
      // return this.http.get(Config.JSON.commonSuccessJson);
    },
    parameterSetuploadParameterFilter: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.requestHeadersStationwiseReport, payLoad);
      // return this.http.get(Config.JSON.loadParameterFilter);
    },
    loadAddParameter: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.filterParameter, payLoad);
      // return this.http.get(Config.JSON.loadAddParameter);
    },
    addNewParameter: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.addParameter, payLoad);
      // return this.http.get(Config.JSON.commonSuccessJson);
    },
    deleteParameter: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteParameter, payLoad);
      // return this.http.get(Config.JSON.commonSuccessJson);
    },
    orderParameter: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.orderParameter, payLoad);
      // return this.http.get(Config.JSON.commonSuccessJson);
    },
    loadActiveTable: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.loadActiveTable, payLoad);
      // return this.http.get(Config.JSON.loadActiveTable);
    },
    loadActiveSearchTable: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.searchUser, payLoad);
      // return this.http.get(Config.JSON.loadActiveSearchTable);
    },
    deleteActiveTableRow: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadActiveTable, payLoad);
      return this.http.get(Config.JSON.loadActiveSearchTable);
    },
    updateActiveTableRow: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadActiveTable, payLoad);
      return this.http.get(Config.JSON.loadActiveSearchTable);
    },
    addActiveTableRow: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadActiveTable, payLoad);
      return this.http.get(Config.JSON.loadActiveSearchTable);
    },
    addStation: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.addStation, payLoad);
      // return this.http.get(Config.JSON.addStation);
    },
    siteSetupAdd: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.siteSetupAdd, payLoad);
      // return this.http.get(Config.JSON.siteSetupAdd);
    },
    siteSetupUpdate: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.siteSetupUpdate, payLoad);
      // return this.http.get(Config.JSON.siteSetupUpdate);
    },
    siteSetupDelete: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.siteSetupDelete, payLoad);
      // return this.http.get(Config.JSON.siteSetupDelete);
    },
    loadIEDSetup: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.formJsonLoader, payLoad);
      // return this.http.get(Config.JSON.loadiedpm1);
    },
    iedAssessmentSetup: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.iedAssessmentSetup, payLoad);
      // return this.http.get(Config.JSON.commonSuccessJson);
    },
    fetchIEDPM1: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.iedSetupFetch, payLoad);
      // return this.http.get(Config.JSON.fetchiedpm1);
    },
    saveIEDPM1: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.iedsetup, payLoad);
      // return this.http.get(Config.JSON.commonSuccessJson);
    },
    loadDynamicLimits: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadDynamicLimits, payLoad);
      return this.http.get(Config.JSON.loadDynamicLimits);
    },
    updateNormalization: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.updateNormalization, payLoad);
      // return this.http.get(Config.JSON.updateNormalization);
    },
    fetchIEDAQRA: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.iedassessmentupFetch, payLoad);
      // return this.http.get(Config.JSON.fetchiedaqra);
    },
    loadAermodMonitoringStation: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.loadAermodMonitoringStation, payLoad);
      return this.http.get(Config.JSON.loadAermodMonitoringStation);
    },
    updateNetwork: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.updateNetwork, payLoad);
      // return this.http.get(Config.JSON.updateNetwork);
    },
    smsAdd: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.smsAdd, payLoad);
      // return this.http.get(Config.JSON.smsAdd);
    },
    emailConfigSave: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.emailConfigSave, payLoad);
    },
    emailConfigLoader: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.emailConfigLoader, payLoad);
    },
    smsEdit: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.smsEdit, payLoad);
      // return this.http.get(Config.JSON.smsEdit);
    },
    smsDelete: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.smsDelete, payLoad);
      // return this.http.get(Config.JSON.smsDelete);
    },
    siteConfigSave: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.siteConfigSave, payLoad);
      // return this.http.get(Config.JSON.siteConfigSave);
    },
    updateStation: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.updateStation, payLoad);
      // return this.http.get(Config.JSON.updateStation);
    },
    deleteStation: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteStation, payLoad);
      // return this.http.get(Config.JSON.deleteStation);
    },
    siteConfUrl: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.siteConfUrl, payLoad);
      // return this.http.get(Config.JSON.deleteStation);
    },
    loadFlagSetup: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.loadFlagsetup, payLoad);
      // return this.http.get(Config.JSON.loadFlagsetup, payLoad);
    },
    loadFlagSetupForm: (payLoad) => {
      return this.http.get(Config.JSON.loadFlagSetupForm);
    },
    flagSetupAdd: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.addFlagsetup, payLoad);
    },
    flagSetupDelete: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteFlagsetup, payLoad);
    },
    flagSetupUpdate: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.editFlagsetup, payLoad);
    },
    // QUALIFIER CODES SETUP
    loadQualifierCodeSetup: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.loadQualifierCodeSetup, payLoad);
      // return this.http.get(Config.JSON.loadQualifierCodeSetup, payLoad);
    },
    loadQualifierCodeSetupForm: (payLoad) => {
      return this.http.get(Config.JSON.loadQualifierCodeSetupForm);
    },
    qualifierCodeAdd: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.qualifierCodeAdd, payLoad);
    },
    qualifierCodeDelete: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.qualifierCodeDelete, payLoad);
    },
    qualifierCodeUpdate: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.qualifierCodeUpdate, payLoad);
    },
    // COMMENTS SETUP
    loadCommentsSetup: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.loadCommentssetup, payLoad);
      // return this.http.get(Config.JSON.loadCommentssetup);
    },
    loadCommentSetupForm: (payLoad) => {
      return this.http.get(Config.JSON.loadCommentSetupForm);
    },
    commentSetupAdd: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.addCommentSetup, payLoad);
    },
    CommentSetupUpdate: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.commentSetupUpdate, payLoad);
    },
    CommentSetupDelete: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.commentSetupDelete, payLoad);
    },
    // CATEGORY SETUP
    loadCategorySetup: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.loadCategorySetup, payLoad);
      // return this.http.get(Config.JSON.loadCategorySetup);
    },
    loadCategorySetupForm: (payLoad) => {
      return this.http.get(Config.JSON.loadCategorySetupForm);
    },
    categorySetupAdd: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.addCategorySetup, payLoad);
    },
    categorySetupUpdate: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.categorySetupUpdate, payLoad);
    },
    categorySetupDelete: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.categorySetupDelete, payLoad);
    },
  };
  public printerServices = {
    CsvDump: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.CsvDump, payLoad);
      // return this.http.get(Config.JSON.CsvDump);
    },
    PdfDump: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.PdfDump, payLoad);
      // return this.http.get(Config.JSON.PdfDump);
    },
    ExcelDump: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.ExcelDump, payLoad);
      // return this.http.get(Config.JSON.excelDump);
    },
    wordDump: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.WordDump, payLoad);
      // return this.http.get(Config.JSON.excelDump);
    },
    WordDump: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.WordDump, payLoad);
      // return this.http.get(Config.JSON.excelDump);
    },
    HTMLDump: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.HTMLDump, payLoad);
      // return this.http.get(Config.JSON.excelDump);
    },
  };
  public AQI_REPORTS = {
    AQIDataReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.AQIDataReport, payLoad);
      // return this.http.get(Config.JSON.AQIDataReport);
    },
    AQILevelReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.AQILevelReport, payLoad);
      // return this.http.get(Config.JSON.AQILevelReport);
    },
    AQIHourlyCalenderView: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.AQIHourlyCalenderView, payLoad);
      // return this.http.get(Config.JSON.AQIHourlyCalenderView);
    },
    AQIMonthlyCalenderView: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.AQIMonthlyCalenderView, payLoad);
      // return this.http.get(Config.JSON.AQIMonthlyCalenderView);
    },
    standard_listing: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.standard_listing, payLoad);
      // return this.http.postText(Config.SERVICE_IDENTIFIER.standard_listing, payLoad, undefined, undefined, true);
    },
    standard_view: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.standard_view, payLoad);
      // return this.http.postText(Config.SERVICE_IDENTIFIER.standard_view, payLoad, undefined, undefined, true);
    },
    standard_generation: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.standard_generation, payLoad);
      // return this.http.postText(Config.SERVICE_IDENTIFIER.standard_generation, payLoad, undefined, undefined, true);
    },
    standard_delete: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.standard_delete, payLoad);
      return this.http.postText(Config.SERVICE_IDENTIFIER.standard_delete, payLoad, true);
    }
  };
  public DATA_VALIDATION = {
    dataValidationTable: (payLoad, flag?) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.dataValidationTable, payLoad);
      // return this.http.get(Config.JSON.dataValidationTable);
    }
  };
  public AERMOD = {
    get_all_projects: (payLoad) => {
      // return this.http.get(Config.JSON.get_all_projects);
      return this.http.get(Config.SERVICE_IDENTIFIER.get_all_projects);
    },
    get_project_generated: (payLoad) => {
      // return this.http.get(Config.JSON.get_project_generated);
      return this.http.postText(Config.SERVICE_IDENTIFIER.get_project_generated, payLoad);
    },
    fetch_all_sites: (payLoad) => {
      // return this.http.get(Config.JSON.fetch_all_sites);
      return this.http.get(Config.SERVICE_IDENTIFIER.fetch_all_sites);
    },
    fetch_site_metadata: (payLoad) => {
      // return this.http.get(Config.JSON.fetch_site_metadata);
      return this.http.postText(Config.SERVICE_IDENTIFIER.fetch_site_metadata, payLoad);
    },
    fetch_list_of_buildings: (payLoad) => {
      // return this.http.get(Config.JSON.fetch_list_of_buildings);
      return this.http.postText(Config.SERVICE_IDENTIFIER.fetch_list_of_buildings, payLoad);
    },
    get_site_terrain_details: (payLoad) => {
      // return this.http.get(Config.JSON.get_site_terrain_details);
      return this.http.postText(Config.SERVICE_IDENTIFIER.get_site_terrain_details, payLoad);
    },
    insert_building_component: (payLoad) => {
      // return this.http.get(Config.JSON.insert_building_component);
      return this.http.postText(Config.SERVICE_IDENTIFIER.insert_building_component, payLoad);
    },
    save_project_details: (payLoad) => {
      // return this.http.get(Config.JSON.insert_building_component);
      return this.http.postText(Config.SERVICE_IDENTIFIER.save_project_details, payLoad);
    },
    update_building_component: (payLoad) => {
      // return this.http.get(Config.JSON.insert_building_component);
      return this.http.postText(Config.SERVICE_IDENTIFIER.update_building_component, payLoad);
    },
    generate_heatmap: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.generate_heatmap, payLoad);
    },
    downloadReport: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.downloadAermodHeatMapReport, payLoad);
    },
    get_project_details: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.get_project_details, payLoad);
    },
  };
  public COMMON_UTILITY_FUNCTIONS = {
    openInNewWindow: (url, width?, height?) => {
      const currentUrl = window.location.href.split('/landing/');
      url = currentUrl[0] + url;
      window.open(url, '', 'width=' + (width || 1080) + ', height=' + (height || 720) + ', scrollbars=yes');
    },
    openInNewTab: (url) => {
      const currentUrl = window.location.href.split('/landing/');
      url = currentUrl[0] + url;
      window.open(url, '_blank');
    }
  };

  public STATE_AQI_DATA_ENTRY = {
    AqiDataEntry: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.AqiDataEntry, payLoad);
      // return this.http.get(Config.JSON.fetchAqiDataEntryList);
    },
    fetchAqiDataEntryForm: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.AqiDataEntry, payLoad);
      // return this.http.get(Config.JSON.fetchAqiDataEntryForm);
    },
    aqiEntryParamsMetaData: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.aqiEntryParamsMetaData, payLoad);
      // return this.http.get(Config.JSON.aqiEntryParamsMetaData);
    },
    AAQMS_Station_Form: (payLoad) => {
      // return this.http.postText(Config.SERVICE_IDENTIFIER.aaqmsStationForm, payLoad);
      return this.http.get(Config.JSON.aaqmsStationForm);
    },
    save_AAQMS_Station_Form: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.saveAAQMSStationForm, payLoad);
      // return this.http.get(Config.JSON.aaqmsStationForm);
    },
    getAAQMSStationList: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.getAAQMSStationList, payLoad);
      // return this.http.get(Config.JSON.getAAQMSStationList);
    },
    deleteaaqmsStation: (payLoad) => {
      return this.http.postText(Config.SERVICE_IDENTIFIER.deleteaaqmsStation, payLoad);
      // return this.http.get(Config.JSON.getAAQMSStationList);
    },
    excelUpload: (payLoad,data) => {
      return this.http.postFile(Config.SERVICE_IDENTIFIER.excelUpload, payLoad, data);
    }
  };
  // For shared nap grid module Testing using Static Json
  dataValidationTableStatic() {
    // return this.http.get(Config.SERVICE_IDENTIFIER.dataValidationStatic);
    return this.http.postText(Config.SERVICE_IDENTIFIER.dataValidationTable);
  }

  submitDataValidation(payLoad, flag?) {
    return this.http.postText(Config.SERVICE_IDENTIFIER.submitDataValidation, payLoad, flag);
  }
  revertDataValidation(payLoad, flag?) {
    return this.http.postText(Config.SERVICE_IDENTIFIER.revertDataValidation, payLoad, flag);
  }

  allMetadataSubmit(payLoad, flag?) {
    return this.http.postText(Config.SERVICE_IDENTIFIER.allMetadataSubmit, payLoad, flag);
  }
  fetchDataValidationConstants(payLoad) {
    return this.http.postText(Config.SERVICE_IDENTIFIER.fetchDataValidationConstants, payLoad);
  }
  
  // COMPLAINT TRACKER
  getComplaintDetails(payLoad:any){
    return this.http.postText(Config.SERVICE_IDENTIFIER.fetchComplaint, payLoad);
  }
}
