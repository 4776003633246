import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LayoutService } from 'src/app/layout/layout.service';


@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {
  @Input() options: DatePickerOptions;
  @Input() inputModel;
  @Output() inputModelChange = new EventEmitter<string>();
  public currentDate: any = new Date();
  public temp: any;
  public stateName: any;
  public complaintTypeSelected: any;
  public minValue;

  constructor(
    public layoutService: LayoutService,
  ) { }

  ngOnInit() {
    this.stateName = JSON.parse(localStorage.getItem('state'));
    this.complaintTypeSelected = JSON.parse(localStorage.getItem('complaintTypeSelected'));
    if (this.stateName === 'Telangana' && this.complaintTypeSelected === 'current') {
      this.minValue = new Date("2023-12-17 00:00:00")
    } else if (this.stateName === 'Telangana' && this.complaintTypeSelected === 'previous') {
      this.currentDate = new Date("2023-12-16 00:00:00")
    }
    this.temp = this.layoutService.getDateFlag();
    this.checkValidDate();
    
  }
  checkValidDate() {
    if(this.temp === true){
      if(!this.inputModel){
        this.temporary();
      }
    }else{
      this.temporary();
    }
    
  }

  temporary(){
    let fromDate = new Date();
    let toDate = new Date();
    if (this.stateName === 'Telangana' && this.complaintTypeSelected === 'current') {
      fromDate.setDate(fromDate.getDate() - 10);
      fromDate.setHours(0, 0, 0, 0);
    } else if (this.stateName === 'Telangana' && this.complaintTypeSelected === 'previous') {
      fromDate = new Date("2023-12-06 00:00:00")
      toDate = new Date("2023-12-16 00:00:00")
    } else {
      fromDate.setDate(fromDate.getDate() - 1);
      fromDate.setHours(0, 0, 0, 0);
    }
    // toDate.setHours(0, 0, 0, 0);
    if (this.options.selectMode === 'range') {
      this.inputModel = [fromDate, toDate];
    } else {
      this.inputModel = new Date();
    }
    setTimeout(() => {
      this.inputModelChange.emit(this.inputModel);
    }, 300);
  }
}

export class DatePickerOptions {
  selectMode: 'range' | 'single';
  required: boolean;
  fromDateLabel?: string;
  toDateLabel?: string;
  name?: string;
  pickerType: 'both' | 'calendar' | 'timer';
}
