import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { SessionhandlerService } from '../services/sessionhandler.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private toggleModal = new BehaviorSubject<any>(false);
  private siteData = new BehaviorSubject<any>({}); 
  public staticFilter = {};
  public fieldData:any =  [{
    "disabled": true,
    "selectMode": "range",
    "placeholder": "Time Range",
    "name": "Time Range",
    "key": "date",
    "type": "date",
    "required": true,
    "pickerType": "both",
    "columns": "8",
    "identifier": "dateRange",
    "hidden": true
},
{
    "disabled": true,
    "selectMode": "range",
    "placeholder": "Time Range",
    "name": "Time Range",
    "key": "date",
    "type": "date",
    "required": true,
    "pickerType": "calendar",
    "columns": "4",
    "identifier": "calendarRange",
    "hidden": false
},
{
    "disabled": true,
    "selectMode": "range",
    "placeholder": "Time Range",
    "name": "Time Range",
    "key": "date",
    "type": "date",
    "required": true,
    "pickerType": "timer",
    "columns": "4",
    "identifier": "timeRange",
    "hidden": true
},
{
    "disabled": true,
    "selectMode": "single",
    "placeholder": "Time Range",
    "name": "Time Range",
    "key": "date",
    "type": "date",
    "required": true,
    "pickerType": "both",
    "columns": "4",
    "identifier": "singleDate",
    "hidden": true
},
{
    "disabled": true,
    "selectMode": "single",
    "placeholder": "Time Range",
    "name": "Time Range",
    "key": "date",
    "type": "date",
    "required": true,
    "pickerType": "calendar",
    "columns": "4",
    "identifier": "singleCalendar",
    "hidden": true
},
{
    "disabled": true,
    "selectMode": "single",
    "placeholder": "Time Range",
    "name": "Time Range",
    "key": "date",
    "type": "date",
    "required": true,
    "pickerType": "timer",
    "columns": "4",
    "identifier": "singleTime",
    "hidden": true
},
{
    "key": "stations",
    "placeholder": "Stations",
    "bindValue": "key",
    "name": "Stations",
    "required": true,
    "bindLabel": "label",
    "type": "multiselect",
    "options": [
        {
            "key": "site_262",
            "label": "Central University, Hyderabad - TSPCB"
        },
        {
            "key": "site_251",
            "label": "ICRISAT Patancheru, Hyderabad - TSPCB"
        },
        {
            "key": "site_199",
            "label": "Bollaram Industrial Area, Hyderabad - TSPCB"
        },
        {
            "key": "site_275",
            "label": "IDA Pashamylaram, Hyderabad - TSPCB"
        },
        {
            "key": "site_298",
            "label": "Zoo Park, Hyderabad - TSPCB"
        },
        {
            "key": "site_294",
            "label": "Sanathnagar, Hyderabad - TSPCB"
        },
        {
            "key": "site_5600",
            "label": "Nacharam_TSIIC IALA, Hyderabad - TSPCB"
        },
        {
            "key": "site_5596",
            "label": "ECIL Kapra, Hyderabad - TSPCB"
        },
        {
            "key": "site_5597",
            "label": "IITH Kandi, Hyderabad - TSPCB"
        },
        {
            "key": "site_5598",
            "label": "Somajiguda, Hyderabad - TSPCB"
        },
        {
            "key": "site_5595",
            "label": "New Malakpet, Hyderabad - TSPCB"
        },
        {
            "key": "site_5604",
            "label": "Kokapet, Hyderabad - TSPCB"
        }
    ],
    "multipleDependency": true,
    "dependencyKeys": [
        "parameter",
        "parameters"
    ],
    "hidden": true
},
{
    "key": "city",
    "placeholder": "City",
    "bindValue": "key",
    "name": "City",
    "bindNameTo": "cityName",
    "required": true,
    "bindLabel": "label",
    "type": "select",
    "options": [
        {
            "key": "Hyderabad",
            "label": "Hyderabad"
        }
    ],
    "dependentOptions": {
        "Hyderabad": [
            {
                "label": "Central University, Hyderabad - TSPCB",
                "key": "site_262"
            },
            {
                "label": "ICRISAT Patancheru, Hyderabad - TSPCB",
                "key": "site_251"
            },
            {
                "label": "Bollaram Industrial Area, Hyderabad - TSPCB",
                "key": "site_199"
            },
            {
                "label": "IDA Pashamylaram, Hyderabad - TSPCB",
                "key": "site_275"
            },
            {
                "label": "Zoo Park, Hyderabad - TSPCB",
                "key": "site_298"
            },
            {
                "label": "Sanathnagar, Hyderabad - TSPCB",
                "key": "site_294"
            },
            {
                "label": "Nacharam_TSIIC IALA, Hyderabad - TSPCB",
                "key": "site_5600"
            },
            {
                "label": "ECIL Kapra, Hyderabad - TSPCB",
                "key": "site_5596"
            },
            {
                "label": "IITH Kandi, Hyderabad - TSPCB",
                "key": "site_5597"
            },
            {
                "label": "Somajiguda, Hyderabad - TSPCB",
                "key": "site_5598"
            },
            {
                "label": "New Malakpet, Hyderabad - TSPCB",
                "key": "site_5595"
            },
            {
                "label": "Kokapet, Hyderabad - TSPCB",
                "key": "site_5604"
            }
        ]
    },
    "multipleDependency": true,
    "dependencyKeys": [],
    "hidden": true
},
{
    "placeholder": "Station",
    "type": "select",
    "name": "Station",
    "key": "station",
    "options": [
        {
            "key": "site_262",
            "label": "Central University, Hyderabad - TSPCB"
        },
        {
            "key": "site_251",
            "label": "ICRISAT Patancheru, Hyderabad - TSPCB"
        },
        {
            "key": "site_199",
            "label": "Bollaram Industrial Area, Hyderabad - TSPCB"
        },
        {
            "key": "site_275",
            "label": "IDA Pashamylaram, Hyderabad - TSPCB"
        },
        {
            "key": "site_298",
            "label": "Zoo Park, Hyderabad - TSPCB"
        },
        {
            "key": "site_294",
            "label": "Sanathnagar, Hyderabad - TSPCB"
        },
        {
            "key": "site_5600",
            "label": "Nacharam_TSIIC IALA, Hyderabad - TSPCB"
        },
        {
            "key": "site_5596",
            "label": "ECIL Kapra, Hyderabad - TSPCB"
        },
        {
            "key": "site_5597",
            "label": "IITH Kandi, Hyderabad - TSPCB"
        },
        {
            "key": "site_5598",
            "label": "Somajiguda, Hyderabad - TSPCB"
        },
        {
            "key": "site_5595",
            "label": "New Malakpet, Hyderabad - TSPCB"
        },
        {
            "key": "site_5604",
            "label": "Kokapet, Hyderabad - TSPCB"
        }
    ],
    "required": true,
    "bindValue": "key",
    "bindLabel": "label",
    "bindNameTo": "station_id",
    "hidden": true
},
{
    "placeholder": "Standard",
    "type": "select",
    "name": "Standard",
    "key": "standard",
    "options": [
        {
            "key": "AQI",
            "label": "AQI"
        }
    ],
    "required": true,
    "bindValue": "key",
    "bindLabel": "label",
    "hidden": true
},
{
    "placeholder": "Select Average Interval",
    "type": "select",
    "name": "Average Interval",
    "key": "averageInterval",
    "options": [
        {
            "label": "15 minutes",
            "key": "15Min"
        },
        {
            "label": "1 Hour",
            "key": "1Hr"
        }
    ],
    "required": true,
    "bindValue": "key",
    "bindLabel": "label",
    "hidden": true
},
{
    "key": "stationType",
    "placeholder": "Station Type",
    "bindValue": "key",
    "name": "Station Type",
    "bindNameTo": "stationType",
    "required": true,
    "bindLabel": "label",
    "type": "select",
    "options": [
        {
            "key": "aaqms",
            "label": "AAQMS"
        },
        {
            "key": "caaqms",
            "label": "CAAQMS"
        }
    ],
    "dependencyKeys": [],
    "hidden": true
}]
  public staticFilterFields = new BehaviorSubject<any>([]);
  public allFilters = new BehaviorSubject<any>({ fields: [], data: { date: [] } });
  public dateFlag : boolean;
  public staticCalenderDFM = [
    {
      disabled: true,
      selectMode: 'range',
      placeholder: 'Time Range',
      name: 'Time Range',
      key: 'date',
      type: 'date',
      required: true,
      pickerType: 'both',
      columns: '8',
      identifier: 'dateRange'
    },
    {
      disabled: true,
      selectMode: 'range',
      placeholder: 'Time Range',
      name: 'Time Range',
      key: 'date',
      type: 'date',
      required: true,
      pickerType: 'calendar',
      columns: '4',
      identifier: 'calendarRange'
    },
    {
      disabled: true,
      selectMode: 'range',
      placeholder: 'Time Range',
      name: 'Time Range',
      key: 'date',
      type: 'date',
      required: true,
      pickerType: 'timer',
      columns: '4',
      identifier: 'timeRange'
    },
    {
      disabled: true,
      selectMode: 'single',
      placeholder: 'Time Range',
      name: 'Time Range',
      key: 'date',
      type: 'date',
      required: true,
      pickerType: 'both',
      columns: '4',
      identifier: 'singleDate'
    },
    {
      disabled: true,
      selectMode: 'single',
      placeholder: 'Time Range',
      name: 'Time Range',
      key: 'date',
      type: 'date',
      required: true,
      pickerType: 'calendar',
      columns: '4',
      identifier: 'singleCalendar'
    },
    {
      disabled: true,
      selectMode: 'single',
      placeholder: 'Time Range',
      name: 'Time Range',
      key: 'date',
      type: 'date',
      required: true,
      pickerType: 'timer',
      columns: '4',
      identifier: 'singleTime'
    }
  ];
  public duplicates = {
    stations: {
      key: 'stations',
      placeholder: 'Stations',
      bindValue: 'key',
      name: 'Stations',
      required: true,
      bindLabel: 'label',
      type: 'multiselect',
      options: [],
      dependentOptions: {},
      multipleDependency: true,
      dependencyKeys: ['parameter', 'parameters']
    }
  };
  public formattedYesterdayDateTime;
  public formattedCurrentDateTime;
  public stateName;
  public complaintTypeSelected;
  constructor(public storage: SessionhandlerService) {
      this.setInitialFilter();
   }
 setInitialFilter() {
    this.stateName = JSON.parse(localStorage.getItem('state'));
    this.complaintTypeSelected = JSON.parse(localStorage.getItem('complaintTypeSelected'));
    if(this.stateName === 'Telangana' ) {
        if(this.complaintTypeSelected === 'current') {
            let { formattedYesterdayDateTime, formattedCurrentDateTime } = this.getInitialData();
            let dateYesterday = formattedYesterdayDateTime.split(' ')[0]
            let timeYesterday = formattedYesterdayDateTime.split(' ')[1]
            this.formattedYesterdayDateTime = dateYesterday + 'T' + timeYesterday + 'Z';
            const dateCurrent = formattedCurrentDateTime.split(' ')[0]
            const timeCurrent = formattedCurrentDateTime.split(' ')[1]
            this.formattedCurrentDateTime = dateCurrent + 'T' + timeCurrent + 'Z';
            const localYesterdayDate = new Date(this.formattedYesterdayDateTime)
            localYesterdayDate.setMinutes(localYesterdayDate.getMinutes() - 330);
            const localCurrentDate = new Date(this.formattedCurrentDateTime)
            localCurrentDate.setMinutes(localCurrentDate.getMinutes() - 330);
            this.allFilters.next({fields : this.fieldData, data: { date: [localYesterdayDate.toISOString(),localCurrentDate.toISOString()] }});
        } else if(this.complaintTypeSelected === 'previous'){
            this.allFilters.next({fields : this.fieldData, data: { date: ['2023-12-05T18:30:00.000Z', '2023-12-15T18:30:00.000Z'] }});
        }
    }
 }
  getInitialData(){
    // Current date and time
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = String(now.getMonth() + 1).padStart(2, '0');
    const currentDay = String(now.getDate()).padStart(2, '0');
    const currentHours = String(now.getHours()).padStart(2, '0');
    const currentMinutes = String(now.getMinutes()).padStart(2, '0');
    const currentSeconds = String(now.getSeconds()).padStart(2, '0');
    const formattedCurrentDateTime = `${currentYear}-${currentMonth}-${currentDay} ${currentHours}:${currentMinutes}:${currentSeconds}`;

    // Yesterday's starting date and time
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 10);
    yesterday.setHours(0, 0, 0, 0);
    const yesterdayYear = yesterday.getFullYear();
    const yesterdayMonth = String(yesterday.getMonth() + 1).padStart(2, '0');
    const yesterdayDay = String(yesterday.getDate()).padStart(2, '0');
    const formattedYesterdayDateTime = `${yesterdayYear}-${yesterdayMonth}-${yesterdayDay} 00:00:00`;

    return({formattedYesterdayDateTime,formattedCurrentDateTime})
  }
  setToggleModal(flag) {
    if (!flag) {
      document.getElementById('filterModalCloseBtn').click();
    }
    this.toggleModal.next(flag);
  }
  getToggleModal(): Observable<any> {
    return this.toggleModal.asObservable();
  }
  setFiltersData(data) {
    this.allFilters.next(data);
  }
  getFiltersData() {
    return this.allFilters.asObservable();
  }
  setSiteData(data) {
    this.storage.api.local.save('siteId', data.key);
    this.storage.api.local.save('siteName', data.label);
    this.siteData.next(data);
  }
  getSiteData(): Observable<any> {
    return this.siteData.asObservable();
  }
  getSitesListDD(list) {
    const keys = Object.keys(list.siteName);
    const optionsList = [];
    keys.forEach(ele => {
      optionsList.push({ key: ele, label: list.siteName[ele] });
    });
    return optionsList;
  }

  // global filters code
  getAppliedStaticFilters() {
    return this.staticFilter;
  }
  setAppliedStaticFilters(data) {
    this.staticFilter = data;
  }
  // DFM JSON
  setStaticFilterFields(data) {
    this.staticFilterFields.next([...this.staticCalenderDFM, ...data]);
  }
  getStaticFilterFields(): Observable<any> {
    return this.staticFilterFields.asObservable();
  }
  setDateFlag(data){
    this.dateFlag = data;
  }
  getDateFlag(){
    return this.dateFlag;
  }
}
