import { Component, OnInit, Input } from '@angular/core';
import { LayoutService } from 'src/app/layout/layout.service';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-applied-filter-display',
  templateUrl: './applied-filter-display.component.html',
  styleUrls: ['./applied-filter-display.component.scss']
})
export class AppliedFilterDisplayComponent implements OnInit {
  @Input() data: any = {};
  public fields;
  public stateName;
  constructor(public layoutService: LayoutService) { }
  ngOnInit() {
    this.stateName = JSON.parse(localStorage.getItem('state'));
    this.onLoadFunctions();
  }
  onLoadFunctions() {
    this.layoutService.getFiltersData().subscribe(response => {
      this.onFiltersChange(response);
    });
  }
  onFiltersChange(serviceData) {
    try {
      const fields = serviceData.fields.filter(ele => !ele.hidden);
      const savedDataKeys = Object.keys(serviceData.data);
      const labels = [];
      let index;
      savedDataKeys.forEach(key => {
        index = fields.findIndex(ele => ele.key === key);
        if (index > -1) {
          const labelMappings = ['select', 'multiselect'];
          const obj = { value: serviceData.data[key], field: fields[index] };
          if (labelMappings.indexOf(fields[index].type) > -1) {
            // apply logic for multiselect here
            if (fields[index].type === 'multiselect') {
              obj.value = [];
              serviceData.data[key].forEach((val, i) => {
                obj.value.push(this.bindValueToObj(fields, index, serviceData, key, i));
              });
            } else {
              obj.value = this.bindValueToObj(fields, index, serviceData, key);
            }
          }
          labels.push(obj);
        }
      });
      this.fields = labels;
    } catch (error) {
      Config.logger(error);
    }
  }
  bindValueToObj(fields, index, serviceData, key, i?) {
    let value = '';
    try {
      if (typeof (fields[index].options[0]) === 'object') {
        const labelIndex = fields[index].options.findIndex(opt =>
          opt[fields[index].bindValue || 'key'] === (i !== undefined ? serviceData.data[key][i] : serviceData.data[key]));
        if (labelIndex > -1 && fields[index].options[labelIndex]) {
          value = fields[index].options[labelIndex][fields[index].bindLabel || ''];
        }
      }
    } catch (error) {
      Config.logger(error);
    }
    return value;
  }
}
